<template>
  <div
    class="labelButton"
    @click="$emit('click')"
    v-html="formattedLabel"
  />
</template>

<script>
function escapeHtml (string) {
  const htmlCharacter = {
    '&': '&amp;',
    '\'': '&#x27;',
    '`': '&#x60;',
    '"': '&quot;',
    '<': '&lt;',
    '>': '&gt;'
  }
  return string.replace(/[&'`"<>]/g, (match) => htmlCharacter[match])
}
const urlRegExp = /(\s|^)((https?|ftp):\/\/)([\da-z.-]+)\.([a-z.]{2,6})([\w-/?=#.])*/gi

export default {
  props: {
    label: {
      type: String,
      required: true
    }
  },
  computed: {
    formattedLabel () {
      return escapeHtml(this.label).replace(urlRegExp, (match) => {
        return (` <a href="${match}" target="_blank" rel="nofollow">${match}</a> `)
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../styles/constants";

.labelButton {
  color: $primary-d;
  word-break: break-all;
  padding: 0;
  text-align: left;
  border: none;
  line-height: $editorLineHeight;
  min-height: $editorLineHeight;
  width: 100%;
  cursor: pointer;
  white-space: pre-line;
  background-color: transparent;
  font-size: $editorTextSize;
  font-family: inherit;
  user-select: none;

  &:focus {
    outline: none;
  }
}
</style>
