<template>
  <textarea-autosize
    v-shortkey.avoid
    v-focus
    class="labelInput"
    :value="label"
    @keydown.native.enter.exact="onKeyDownEnter"
    @keyup.native.esc.exact="$el.blur()"
    @blur.native="onNativeBlur"
  />
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      composing: false
    }
  },
  methods: {
    onNativeBlur (e) {
      if (e instanceof KeyboardEvent) {
        this.$el.blur()
      } else {
        this.$emit('blur', e.target.value)
      }
    },
    onKeyDownEnter (e) {
      if (e.isComposing === false) {
        this.$el.blur()
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../styles/constants";

.labelInput {
  color: $primary-d;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  border: none;
  display: block;
  line-height: $editorLineHeight;
  font-size: $editorTextSize;
  font-family: inherit;

  &:focus {
    outline: 2px $theme-a dotted;
  }
}
</style>
