<template>
  <label
    class="toggleChildren"
  >
    {{ childrenIsVisible? '-' : '+' }}
    <input
      type="checkbox"
      class="input"
      :checked="childrenIsVisible"
      @input="$emit('input')"
    >
  </label>
</template>

<script>
export default {
  props: {
    childrenIsVisible: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../styles/constants";

.toggleChildren {
  display: flex;
  width: calc(1.2rem);
  height: calc(1.2rem);
  background-color: $gray-4;
  box-sizing: border-box;
  border: none;
  padding: 0;
  position: absolute;
  top: 3px;
  right: 3px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: $primary-l;
  user-select: none;

  &:hover {
    filter: brightness(90%);
  }
}

.input {
  display: none;
}
</style>
