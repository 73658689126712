var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"shortKeyListner"},[(_vm.isMac)?_c('div',{directives:[{name:"shortkey",rawName:"v-shortkey",value:({
      editDescription: ['meta', 'enter'],
      editLabel: ['enter'],
      deleteNode: ['del'],
      addChild: ['meta', 'arrowright'],
      addSiblings: ['meta', 'arrowdown'],
      void: ['meta', 'arrowleft'],
      copyNode: ['meta', 'c'],
      cutNode: ['meta', 'x'],
      pasteNodeToChild: ['meta', 'v'],
      pasteNodeToSibling: ['meta','shift', 'v'],
      moveUpNode: ['meta', 'ctrl', 'arrowup'],
      moveDownNode: ['meta', 'ctrl', 'arrowdown'],
      moveRightNode: ['meta', 'ctrl', 'arrowright'],
      moveLeftNode: ['meta', 'ctrl', 'arrowleft'],
    }),expression:"{\n      editDescription: ['meta', 'enter'],\n      editLabel: ['enter'],\n      deleteNode: ['del'],\n      addChild: ['meta', 'arrowright'],\n      addSiblings: ['meta', 'arrowdown'],\n      void: ['meta', 'arrowleft'],\n      copyNode: ['meta', 'c'],\n      cutNode: ['meta', 'x'],\n      pasteNodeToChild: ['meta', 'v'],\n      pasteNodeToSibling: ['meta','shift', 'v'],\n      moveUpNode: ['meta', 'ctrl', 'arrowup'],\n      moveDownNode: ['meta', 'ctrl', 'arrowdown'],\n      moveRightNode: ['meta', 'ctrl', 'arrowright'],\n      moveLeftNode: ['meta', 'ctrl', 'arrowleft'],\n    }"}],on:{"shortkey":function (e){this$1.$emit('detectEditorShortkey',e)}}},[_vm._v(" editShortkeys ")]):_c('div',{directives:[{name:"shortkey",rawName:"v-shortkey",value:({
      editDescription: ['ctrl', 'enter'],
      editLabel: ['enter'],
      deleteNode: ['del'],
      addChild: ['ctrl', 'arrowright'],
      addSiblings: ['ctrl', 'arrowdown'],
      void: ['ctrl', 'arrowleft'],
      copyNode: ['ctrl', 'c'],
      cutNode: ['ctrl', 'x'],
      pasteNodeToChild: ['ctrl', 'v'],
      pasteNodeToSibling: ['ctrl','shift', 'v'],
      moveUpNode: ['ctrl', 'alt', 'arrowup'],
      moveDownNode: ['ctrl', 'alt', 'arrowdown'],
      moveRightNode: ['ctrl', 'alt', 'arrowright'],
      moveLeftNode: ['ctrl', 'alt', 'arrowleft'],
    }),expression:"{\n      editDescription: ['ctrl', 'enter'],\n      editLabel: ['enter'],\n      deleteNode: ['del'],\n      addChild: ['ctrl', 'arrowright'],\n      addSiblings: ['ctrl', 'arrowdown'],\n      void: ['ctrl', 'arrowleft'],\n      copyNode: ['ctrl', 'c'],\n      cutNode: ['ctrl', 'x'],\n      pasteNodeToChild: ['ctrl', 'v'],\n      pasteNodeToSibling: ['ctrl','shift', 'v'],\n      moveUpNode: ['ctrl', 'alt', 'arrowup'],\n      moveDownNode: ['ctrl', 'alt', 'arrowdown'],\n      moveRightNode: ['ctrl', 'alt', 'arrowright'],\n      moveLeftNode: ['ctrl', 'alt', 'arrowleft'],\n    }"}],on:{"shortkey":function (e){this$1.$emit('detectEditorShortkey',e)}}},[_vm._v(" editShortkeys ")]),_c('div',{directives:[{name:"shortkey",rawName:"v-shortkey",value:({
      up: ['arrowup'],
      down: ['arrowdown'],
      right: ['arrowright'],
      left: ['arrowleft'],
    }),expression:"{\n      up: ['arrowup'],\n      down: ['arrowdown'],\n      right: ['arrowright'],\n      left: ['arrowleft'],\n    }"}],on:{"shortkey":function (e){this$1.$emit('detectSelectionShortkey',e)}}},[_vm._v(" selectionShortkeys ")]),_c('div',{directives:[{name:"shortkey",rawName:"v-shortkey.push",value:(['f1']),expression:"['f1']",modifiers:{"push":true}}],on:{"shortkey":function($event){return _vm.$emit('detectToggleHelpShortkey')}}},[_vm._v(" toggleHelp ")])])}
var staticRenderFns = []

export { render, staticRenderFns }