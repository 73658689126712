<template>
  <div class="shortKeyListner">
    <div
      v-if="isMac"
      v-shortkey="{
        editDescription: ['meta', 'enter'],
        editLabel: ['enter'],
        deleteNode: ['del'],
        addChild: ['meta', 'arrowright'],
        addSiblings: ['meta', 'arrowdown'],
        void: ['meta', 'arrowleft'],
        copyNode: ['meta', 'c'],
        cutNode: ['meta', 'x'],
        pasteNodeToChild: ['meta', 'v'],
        pasteNodeToSibling: ['meta','shift', 'v'],
        moveUpNode: ['meta', 'ctrl', 'arrowup'],
        moveDownNode: ['meta', 'ctrl', 'arrowdown'],
        moveRightNode: ['meta', 'ctrl', 'arrowright'],
        moveLeftNode: ['meta', 'ctrl', 'arrowleft'],
      }"
      @shortkey="(e)=>{this.$emit('detectEditorShortkey',e)}"
    >
      editShortkeys
    </div>
    <div
      v-else
      v-shortkey="{
        editDescription: ['ctrl', 'enter'],
        editLabel: ['enter'],
        deleteNode: ['del'],
        addChild: ['ctrl', 'arrowright'],
        addSiblings: ['ctrl', 'arrowdown'],
        void: ['ctrl', 'arrowleft'],
        copyNode: ['ctrl', 'c'],
        cutNode: ['ctrl', 'x'],
        pasteNodeToChild: ['ctrl', 'v'],
        pasteNodeToSibling: ['ctrl','shift', 'v'],
        moveUpNode: ['ctrl', 'alt', 'arrowup'],
        moveDownNode: ['ctrl', 'alt', 'arrowdown'],
        moveRightNode: ['ctrl', 'alt', 'arrowright'],
        moveLeftNode: ['ctrl', 'alt', 'arrowleft'],
      }"
      @shortkey="(e)=>{this.$emit('detectEditorShortkey',e)}"
    >
      editShortkeys
    </div>
    <div
      v-shortkey="{
        up: ['arrowup'],
        down: ['arrowdown'],
        right: ['arrowright'],
        left: ['arrowleft'],
      }"
      @shortkey="(e)=>{this.$emit('detectSelectionShortkey',e)}"
    >
      selectionShortkeys
    </div>
    <div
      v-shortkey.push="['f1']"
      @shortkey="$emit('detectToggleHelpShortkey')"
    >
      toggleHelp
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isMac: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style scoped>
  .shortKeyListner {
    display: none;
  }
</style>
