<template>
  <div class="nodeIndex">
    <slot>node index</slot>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
@import "../../../styles/constants";

.nodeIndex {
  position: absolute;
  top: -11px;
  left: 0;
  line-height: 1;
  // width: 100%;
  height: 10px;
  color: $low-d;
  transform: scale(0.8);
  user-select: none;
}
</style>
