<template>
  <div class="editorHeader">
    <div class="summary">
      <div class="title">{{title}}</div>
    </div>
    <div class="action">
      <span class="help">Show help: F1</span>
      <div class="actionButtons">
        <flat-icon-button
          @click="showExportMenu"
          v-click-outside="hideExportMenu"
        >
          <dots-vertical-icon title="show menu"/>
        </flat-icon-button>
        <div
          v-if="exportMenuIsShow"
          class="exportMenu"
        >
          <ul class="dropdown">
            <li class="dropdownMenu">
              <button
                class="dropdownMenuButton"
                @click="$emit('clickExportToImage')"
              >Export to image</button>
            </li>
            <li class="dropdownMenu">
              <button
                class="dropdownMenuButton"
                @click="$emit('clickExportToText')"
              >Export to text</button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import FlatIconButton from '../../../components/common/atoms/FlatIconButton'
import DotsVerticalIcon from 'vue-material-design-icons/DotsVertical'

export default {
  name: 'EditorHeader',
  components: {
    FlatIconButton,
    DotsVerticalIcon
  },
  props: {
    title: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      exportMenuIsShow: false
    }
  },
  methods: {
    showExportMenu () {
      this.exportMenuIsShow = true
    },
    hideExportMenu () {
      this.exportMenuIsShow = false
    }
  },
  directives: {
    ClickOutside
  }
}
</script>

<style scoped lang="scss">
  @import '../../../styles/constants';

  .editorHeader {
    height: 40px;
    box-sizing: border-box;
    border-bottom: solid 1px $stroke;
    display: flex;
    align-items: center;
    padding: 0 20px;
    justify-content: space-between;
  }

  .summary {
    display: flex;
    align-items: flex-end;
    width: 50%;
  }

  .action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50%;
  }

  .title {
    color: $primary-d;
    padding: 0;
    margin: 0;
    font-size: 1.8rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: calc(100% - 80px);
  }

  .help {
    @extend %typo-caption;

    color: $secondary-d;
    padding: 0;
  }

  .actionButtons {
    padding: 0 20px;
    position: relative;
  }

  .exportMenu {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 100;
  }

  .dropdown {
    width: 220px;
    background: white;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 $smoke-3;
    margin: 0;
    padding: 8px 0;
    list-style-type: none;
  }

  .dropdownMenuButton {
    @extend %typo-body1;

    color: $primary-d;
    width: 100%;
    border: none;
    background-color: white;
    text-align: left;
    cursor: pointer;
    padding: 8px 16px;

    &:hover {
      filter: brightness(95%);
    }

    &:active {
      filter: brightness(90%);
    }
  }
</style>
