<template>
  <transition name="shortkeyHelp">
    <div class="shortkeyHelp">
      <div class="content">
        <h2 class="title">Help</h2>
        <div class="categories">
          <div class="category">
            <h3 class="categoryName">Node selection</h3>
            <h4 class="shortKey"><kbd class="kbd">arrow up</kbd></h4>
            <p class="description">Select a sibling node at upper position from current node.</p>
            <h4 class="shortKey"><kbd class="kbd">arrow down</kbd></h4>
            <p class="description">Select a sibling node at under position from current node.</p>
            <h4 class="shortKey"><kbd class="kbd">arrow right</kbd></h4>
            <p class="description">Select a child node at top position.</p>
            <h4 class="shortKey"><kbd class="kbd">arrow left</kbd></h4>
            <p class="description">Select parent node of current node.</p>
          </div>
          <div class="category">
            <h3 class="categoryName">Edit node</h3>
            <h4 class="shortKey"><kbd class="kbd">enter</kbd></h4>
            <p class="description">Switch current node's label to edit mode.</p>
            <h4 v-if="isMac" class="shortKey"><kbd class="kbd">command</kbd> + <kbd class="kbd">enter</kbd></h4>
            <h4 v-else class="shortKey"><kbd class="kbd">ctrl</kbd> + <kbd class="kbd">enter</kbd></h4>
            <p class="description">Switch current node's description to edit mode.</p>
            <h4 v-if="isMac" class="shortKey"><kbd class="kbd">fn</kbd> + <kbd class="kbd">delete</kbd></h4>
            <h4 v-else class="shortKey"><kbd class="kbd">delete</kbd></h4>
            <p class="description">Delete current node.</p>
            <h4 v-if="isMac" class="shortKey"><kbd class="kbd">command</kbd> + <kbd class="kbd">arrow right</kbd></h4>
            <h4 v-else class="shortKey"><kbd class="kbd">ctrl</kbd> + <kbd class="kbd">arrow right</kbd></h4>
            <p class="description">Create new child node at top position of current node's children nodes.</p>
            <h4 v-if="isMac" class="shortKey"><kbd class="kbd">command</kbd> + <kbd class="kbd">arrow down</kbd></h4>
            <h4 v-else class="shortKey"><kbd class="kbd">ctrl</kbd> + <kbd class="kbd">arrow down</kbd></h4>
            <p class="description">Create new sigling node at lower position of current node.</p>
          </div>
          <div class="category">
            <h3 class="categoryName">Move node</h3>
            <h4 v-if="isMac" class="shortKey"><kbd class="kbd">command</kbd> + <kbd class="kbd">ctrl</kbd> + <kbd class="kbd">arrow up</kbd></h4>
            <h4 v-else class="shortKey"><kbd class="kbd">ctrl</kbd> + <kbd class="kbd">alt</kbd> + <kbd class="kbd">arrow up</kbd></h4>
            <p class="description">Move up curent node.</p>
            <h4 v-if="isMac" class="shortKey"><kbd class="kbd">command</kbd> + <kbd class="kbd">ctrl</kbd> + <kbd class="kbd">arrow down</kbd></h4>
            <h4 v-else class="shortKey"><kbd class="kbd">ctrl</kbd> + <kbd class="kbd">alt</kbd> + <kbd class="kbd">arrow down</kbd></h4>
            <p class="description">Move down curent node.</p>
            <h4 v-if="isMac" class="shortKey"><kbd class="kbd">command</kbd> + <kbd class="kbd">ctrl</kbd> + <kbd class="kbd">arrow right</kbd></h4>
            <h4 v-else class="shortKey"><kbd class="kbd">ctrl</kbd> + <kbd class="kbd">alt</kbd> + <kbd class="kbd">arrow right</kbd></h4>
            <p class="description">Move right curent node.</p>
            <h4 v-if="isMac" class="shortKey"><kbd class="kbd">command</kbd> + <kbd class="kbd">ctrl</kbd> + <kbd class="kbd">arrow left</kbd></h4>
            <h4 v-else class="shortKey"><kbd class="kbd">ctrl</kbd> + <kbd class="kbd">alt</kbd> + <kbd class="kbd">arrow left</kbd></h4>
            <p class="description">Move left curent node.</p>
          </div>
          <div class="category">
            <h3 class="categoryName">In-app clipboard manipulation</h3>
            <h4 v-if="isMac" class="shortKey"><kbd class="kbd">command</kbd> + <kbd class="kbd">c</kbd></h4>
            <h4 v-else class="shortKey"><kbd class="kbd">ctrl</kbd> + <kbd class="kbd">c</kbd></h4>
            <p class="description">Copy current node and all children to in-app clipboard.</p>
            <h4 v-if="isMac" class="shortKey"><kbd class="kbd">command</kbd> + <kbd class="kbd">x</kbd></h4>
            <h4 v-else class="shortKey"><kbd class="kbd">ctrl</kbd> + <kbd class="kbd">x</kbd></h4>
            <p class="description">Copy current node and all children to in-app clipboard then delete current node.</p>
            <h4 v-if="isMac" class="shortKey"><kbd class="kbd">command</kbd> + <kbd class="kbd">v</kbd></h4>
            <h4 v-else class="shortKey"><kbd class="kbd">ctrl</kbd> + <kbd class="kbd">v</kbd></h4>
            <p class="description">Paste copied node from in-app clipboard at top position of current node's children nodes.</p>
            <h4 v-if="isMac" class="shortKey"><kbd class="kbd">command</kbd> + <kbd class="kbd">shift</kbd> + <kbd class="kbd">v</kbd></h4>
            <h4 v-else class="shortKey"><kbd class="kbd">ctrl</kbd> + <kbd class="kbd">shift</kbd> + <kbd class="kbd">v</kbd></h4>
            <p class="description">Paste copied node from in-app clipboard at lower position of current node.</p>
          </div>
      </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    isMac: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../styles/constants";

.shortkeyHelp {
  position: fixed;
  background-color: $smoke-1;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shortkeyHelp-enter-active,
.shortkeyHelp-leave-active {
  transition: opacity 0.1s;
}

.shortkeyHelp-enter,
.shortkeyHelp-leave-to {
  opacity: 0;
}

.title {
  @extend %typo-h2;

  margin: 0 0 20px;
}

.content {
  background-color: $white;
  padding: 20px;
  box-sizing: border-box;
  min-width: 600px;
  max-width: 95vw;
  max-height: 95vh;
  overflow: scroll;
  border-radius: 6px;
}

.categories {
  display: flex;
  justify-content: space-between;
}

.category {
  flex: 0 0 22%;
}

.categoryName {
  @extend %typo-h3;

  margin: 0 0 10px;
  color: $gray-1;
}

.shortKey {
  @extend %typo-subtitle1;

  margin: 0 0 10px;
  color: $gray-1;
}

.description + .shortKey {
  margin: 20px 0 10px;
}

.description {
  @extend %typo-body2;

  margin: 0 0 5px;
  color: $gray-1;
}

.kbd {
  border: solid 1px $gray-3;
  padding: 0 5px;
  border-radius: 2px;
}
</style>
