<template>
  <li
    class="treeNode"
  >
    <div
      class="labelWrap"
      ref="labelWrap"
      :class="{
        'labelWrap-hasChildren': children.length !== 0 && isShowChildren,
        'labelWrap-selected': selectedNodeId === nodeId
      }"
      @click="onClickSelectNode(nodeId)"
    >
      <node-data
        :index="index"
        :node-id="nodeId"
        :label="label"
        :description="description"
        :is-selected="selectedNodeId === nodeId"
        :edit-target-type="editTargetType"
        :has-children="children.length !== 0"
        :children-is-visible="isShowChildren"
        @inputToggleChildren="onInputToggleChildren"
        @changeDescription="onChangeDescription"
        @changeLabel="onChangeLabel"
        @clickLabel="$emit('clickLabel')"
        @clickDescription="$emit('clickDescription')"
        @blurEditInput="$emit('blurEditInput')"
      />
    </div>
    <transition-group
      v-if="children.length !== 0 && isShowChildren"
      class="children"
      name="list-complete"
      tag="ul"
    >
      <treee-node
        class="list-complete-item"
        v-for="(data, c_index) in children"
        :key="data.id"
        :selected-node-id="selectedNodeId"
        :edit-target-type="editTargetType"
        :index="`${index}.${c_index + 1}`"
        :node-id="data.id"
        :label="data.label"
        :description="data.description"
        :children="treeeNodeSiblings(data.id)"
        :is-show-children="data.children.isVisible"
        @inputToggleChildren="onInputToggleChildren"
        @changeDescription="onChangeDescription"
        @changeLabel="onChangeLabel"
        @clickSelectNode="onClickSelectNode"
        @clickLabel="$emit('clickLabel')"
        @clickDescription="$emit('clickDescription')"
        @blurEditInput="$emit('blurEditInput')"
        @selected="onSelected"
      />
    </transition-group>
  </li>
</template>

<script>
import NodeData from '../molecules/NodeData.vue'
import { createNamespacedHelpers } from 'vuex'
const treeeEditorStore = createNamespacedHelpers('treeeEditorStore')

export default {
  name: 'TreeeNode',
  components: {
    TreeeNode: () => import('./TreeeNode.vue'),
    NodeData
  },
  props: {
    nodeId: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    children: {
      type: Array,
      required: true
    },
    isShowChildren: {
      type: Boolean,
      required: true
    },
    index: {
      type: String,
      required: true
    },
    selectedNodeId: {
      type: String,
      required: true
    },
    editTargetType: {
      type: String,
      required: true
    }
  },
  computed: {
    ...treeeEditorStore.mapGetters([
      'treeeNodeSiblings'
    ])
  },
  watch: {
    selectedNodeId (newId) {
      if (newId === this.nodeId) {
        this.$emit('selected', this.$refs.labelWrap.getBoundingClientRect())
      }
    }
  },
  methods: {
    onSelected (boundingClientRect) {
      this.$emit('selected', boundingClientRect)
    },
    onInputToggleChildren (nodeId) {
      this.$emit('inputToggleChildren', nodeId)
    },
    onChangeDescription (payload) {
      this.$emit('changeDescription', payload)
    },
    onChangeLabel (payload) {
      this.$emit('changeLabel', payload)
    },
    onClickSelectNode (nodeId) {
      this.$emit('clickSelectNode', nodeId)
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../styles/constants";

.treeNode {
  display: flex;
  position: relative;
  align-items: flex-start;

  &:last-child::after {
    // mask for vertical branch
    content: '';
    display: block;
    width: $branchWidth;
    height: calc(100% - #{$branchOffset*2} - #{$branchWidth});
    background-color: $backgroundColor;
    position: absolute;
    top: calc(#{$branchOffset} + #{$branchWidth});
    left: -$branchHorizontalBase;
    z-index: 2;
  }
}

.labelWrap {
  display: flex;
  position: relative;
  background-color: $backgroundColor;
  margin: 0 0 5px;
  padding: 14px $branchHorizontalBase 6px;
  cursor: pointer;

  &:hover {
    background-color: $backgroundColor-selected;
  }

  &::after {
    // horizontal branch
    content: '';
    display: block;
    width: $branchHorizontalBase*2;
    height: 0;
    border-top: dotted $branchWidth $branchColor;
    position: absolute;
    top: $branchOffset;
    left: -$branchHorizontalBase;
  }

  &-hasChildren::after {
    // horizontal branch
    width: calc(100% + #{$branchHorizontalBase*2});
  }

  &-selected {
    background-color: $backgroundColor-selected;
    cursor: default;
  }
}

.children {
  padding: 0 0 0 2 * $branchHorizontalBase;
  margin: 0 0 0;
  list-style-type: none;
  position: relative;

  &::after {
    // vertical branch
    content: '';
    display: block;
    width: 0;
    border-left: dotted $branchWidth $branchColor;
    height: calc(100% - #{$branchOffset*2});
    position: absolute;
    top: $branchOffset;
    left: $branchHorizontalBase;
  }
}

.list-complete-item {
  transition: all 0.2s;
}

.list-complete-enter,
.list-complete-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.list-complete-leave-active {
  position: absolute;
}
</style>
