<template>
  <div class="nodeData">
    <node-index>{{ index }}</node-index>
    <div
      class="label"
      :class="{'label-hasChildren': hasChildren}"
    >
      <label-input
        v-if="isSelected && editTargetType === 'label'"
        ref="labelInput"
        :label="label"
        @blur="onBlurLabel"
      />
      <label-button
        v-else
        @click="onClickLabel"
        :label="label"
      />
      <toggle-children
        v-if="hasChildren"
        :children-is-visible="childrenIsVisible"
        @input="$emit('inputToggleChildren', nodeId)"
      />
    </div>
    <div
      class="description"
      :class="{'description-hide': description.length === 0 && (editTargetType !== 'description' || !isSelected)}"
    >
      <description-input
        v-if="isSelected && editTargetType === 'description'"
        ref="descriptionInput"
        :description="description"
        @blur="onBlurDescription"
      />
      <description-button
        v-else
        @click="onClickDescription"
        :description="description"
      />
    </div>
  </div>
</template>

<script>
import NodeIndex from '../atoms/NodeIndex'
import LabelButton from '../atoms/LabelButton'
import LabelInput from '../atoms/LabelInput'
import ToggleChildren from '../atoms/ToggleChildren'
import DescriptionButton from '../atoms/DescriptionButton'
import DescriptionInput from '../atoms/DescriptionInput'
export default {
  components: {
    NodeIndex,
    LabelButton,
    LabelInput,
    ToggleChildren,
    DescriptionButton,
    DescriptionInput
  },
  props: {
    nodeId: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    hasChildren: {
      type: Boolean,
      required: true
    },
    childrenIsVisible: {
      type: Boolean,
      required: true
    },
    index: {
      type: String,
      required: true
    },
    isSelected: {
      type: Boolean,
      required: true
    },
    editTargetType: {
      type: String,
      required: true
    }
  },
  watch: {},
  methods: {
    onClickLabel () {
      if (this.isSelected) {
        this.$emit('clickLabel')
      }
    },
    onClickDescription () {
      if (this.isSelected) {
        this.$emit('clickDescription')
      }
    },
    onBlurLabel (value) {
      if (this.label !== value) {
        this.$emit('changeLabel', {
          nodeIndex: this.index,
          nodeId: this.nodeId,
          value
        })
      } else {
        this.$emit('blurEditInput')
      }
    },
    onBlurDescription (value) {
      if (this.description !== value) {
        this.$emit('changeDescription', {
          nodeIndex: this.index,
          nodeId: this.nodeId,
          value
        })
      } else {
        this.$emit('blurEditInput')
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../styles/constants";

.nodeData {
  position: relative;
  z-index: 1;
}

.label {
  display: flex;
  width: $nodeWidth;
  box-sizing: border-box;
  z-index: 3;
  padding: 2px 4px;
  border: solid 1px $stroke;
  background-color: $labelBackgroundColor;
  position: relative;

  &-hasChildren {
    padding: 2px calc(1.2rem + 6px) 2px 4px;
  }
}

.debugTip {
  display: none;
  position: absolute;
  background-color: rgba(black, 0.2);
  top: -20px;
  left: 30px;
}

.description {
  width: $nodeWidth - 20px;
  margin: 0 0 0 20px;
  box-sizing: border-box;
  padding: 2px 4px;
  background-color: $wall;

  &-hide {
    display: none;
  }
}
</style>
