<template>
  <div class="treeeEditor">
    <div class="header">
      <editor-header
        :title="title"
        @clickExportToImage="onClickShare"
        @clickExportToText="openExportDialog"
        @clickShowHelp="toggleHelp"
      />
    </div>
    <div
      ref="content"
      class="content"
    >
      <div
        v-if="treeeNodeSiblings('root').length > 0"
        class="contentInner"
      >
        <div ref="treeeWrap" class="treeeWrap" :class="{'capture': capturing}">
          <div v-if="capturing" class="seedNode">{{title}}</div>
          <transition-group
            class="treee"
            name="list-complete"
            tag="ul"
          >
            <treee-node
              class="list-complete-item"
              v-for="(data, index) in treeeNodeSiblings('root')"
              :key="data.id"
              :selected-node-id="selectedNodeId"
              :edit-target-type="editTargetType"
              :index="`${index + 1}`"
              :node-id="data.id"
              :label="data.label"
              :description="data.description"
              :children="treeeNodeSiblings(data.id)"
              :is-show-children="data.children.isVisible"
              @inputToggleChildren="onInputToggleChildren"
              @changeDescription="onChangeDescription"
              @changeLabel="onChangeLabel"
              @clickSelectNode="onClickSelectNode"
              @clickLabel="editTypeLabel"
              @clickDescription="editTypeDescription"
              @blurEditInput="clearEditType"
              @selected="onSelected"
            />
          </transition-group>
        </div>
      </div>
      <div class="loading" v-else>
        <loading-indicator/>
      </div>
    </div>
    <shortkey-listner
      :is-mac="isMac"
      @detectEditorShortkey="onDetectEditorShortkey"
      @detectSelectionShortkey="onDetectSelectionShortkey"
      @detectToggleHelpShortkey="toggleHelp"
    />
    <shortkey-help
      v-if="modalType === 'HELP'"
      :is-mac="isMac"
    />
    <export-to-text
      v-if="modalType === 'EXPORT'"
      @clickCloseExportDialog="closeExportDialog"
    />
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import html2canvas from 'html2canvas'
import { createNamespacedHelpers } from 'vuex'
import TreeeNode from '../components/treeeEditor/organisms/TreeeNode.vue'
import EditorHeader from '../components/treeeEditor/organisms/EditorHeader'
import ShortkeyHelp from '../components/treeeEditor/organisms/ShortkeyHelp'
import ExportToText from '../components/treeeEditor/organisms/ExportToText'
import LoadingIndicator from '../components/common/atoms/LoadingIndicator'
import ShortkeyListner from '../components/treeeEditor/organisms/ShortkeyListner'

const treeeEditorStore = createNamespacedHelpers('treeeEditorStore')
const authStore = createNamespacedHelpers('authStore')

export default {
  components: {
    TreeeNode,
    EditorHeader,
    ShortkeyHelp,
    LoadingIndicator,
    ShortkeyListner,
    ExportToText
  },
  data () {
    return {
      modalType: '',
      isMac: false,
      capturing: false
    }
  },
  computed: {
    ...treeeEditorStore.mapState([
      'flatTreee',
      'selectedNodeId',
      'editTargetType',
      'title',
      'saveStateMessage'
    ]),
    ...treeeEditorStore.mapGetters([
      'treeeNodeSiblings',
      'structuredTreee'
    ]),
    ...authStore.mapState([
      'authorized',
      'displayName'
    ])
  },
  beforeMount () {
    this.getTreee().then(() => {
      document.title = `${this.title} - Treee`
    })
    // window.addEventListener('beforeunload', (e) => {
    //   if (this.changed) {
    //     e.preventDefault()
    //     e.returnValue = ''
    //   }
    // })
    this.isMac = navigator.userAgent.toLowerCase().indexOf('mac') > -1
  },
  destroyed () {
    this.clearTreee()
  },
  methods: {
    ...treeeEditorStore.mapActions([
      'clearTreee',
      'getTreee',
      'toggleChildrenVisibility',
      'updateDescription',
      'updateLabel',
      'selectNode',
      'executeShortkeySelection',
      'executeShortkeyEditorAction',
      'editTypeDescription',
      'editTypeLabel',
      'clearEditType',
      'unselectNode'
    ]),
    ...authStore.mapActions([
      'signOut'
    ]),
    onClickExportToImage () {

    },
    onClickShare () {
      let exported = false
      const exportImage = () => {
        if (!exported) {
          exported = true
          this.capturing = true
          this.$nextTick(() => {
            html2canvas(this.$refs.treeeWrap, { logging: false }).then(canvas => {
              saveBlob(Base64toBlob(canvas.toDataURL('image/png')), `${this.title}.png`)
              this.capturing = false
            })
          })
        }
      }
      exportImage()

      // setTimeout(exportImage, 1000)

      // gtag('event', 'save_image', {
      //   'event_callback': () => {
      //     exportImage()
      //   }
      // })

      function Base64toBlob (base64) {
        const tmp = base64.split(',')
        const data = atob(tmp[1])
        const mime = tmp[0].split(':')[1].split(';')[0]
        const buf = new Uint8Array(data.length)
        for (var i = 0; i < data.length; i++) {
          buf[i] = data.charCodeAt(i)
        }
        return new Blob([buf], { type: mime })
      }
      function saveBlob (blob, fileName) {
        const url = (window.URL || window.webkitURL)
        const dataUrl = url.createObjectURL(blob)
        const event = document.createEvent('MouseEvents')
        event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
        const a = document.createElementNS('http://www.w3.org/1999/xhtml', 'a')
        a.href = dataUrl
        a.download = fileName
        a.dispatchEvent(event)
      }
    },
    onSelected (boundignClientRect) {
      this.$nextTick(() => {
        const c = this.$refs.content
        const n = boundignClientRect
        const top = n.top - c.offsetTop
        const left = n.left
        const right = c.getBoundingClientRect().width - n.right
        const bottom = c.getBoundingClientRect().height - n.bottom
        const scrollMargin = 20

        if (left < 0) { c.scrollBy(left - scrollMargin, 0) }
        if (right < 0) { c.scrollBy(-(right - scrollMargin), 0) }

        if (top < 0) { c.scrollBy(0, top - scrollMargin - c.offsetTop) }
        if (bottom < 0) { c.scrollBy(0, -((bottom - scrollMargin) + c.offsetTop)) }
      })
    },
    onInputToggleChildren (nodeId) {
      this.toggleChildrenVisibility(nodeId)
    },
    onChangeDescription (payload) {
      this.updateDescription(payload)
    },
    onChangeLabel (payload) {
      this.updateLabel(payload)
    },
    onClickSelectNode (nodeId) {
      this.selectNode(nodeId)
    },
    onDetectSelectionShortkey (e) {
      this.executeShortkeySelection(e.srcKey)
    },
    onDetectEditorShortkey (e) {
      this.executeShortkeyEditorAction(e.srcKey)
    },
    toggleHelp () {
      if (this.modalType === 'EXPOT') return
      if (this.modalType === 'HELP') {
        this.modalType = ''
        return
      }
      this.modalType = 'HELP'
    },
    openExportDialog () {
      this.modalType = 'EXPORT'
    },
    closeExportDialog () {
      this.modalType = ''
    }
  },
  directives: {
    ClickOutside
  }
}
</script>

<style lang="scss" scoped>
  @import "../styles/constants";

  .treeeEditor {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .header {
    flex: 0 0 auto;
  }

  .content {
    flex: 1 0 calc(100% - 40px);
    box-sizing: border-box;
    display: inline-flex;
    overflow: auto;
    scroll-behavior: smooth;
    background-color: $backgroundColor;
  }

  .contentInner {
    margin: 0;
  }

  .treeeWrap {
    padding: 20px;
    position: relative;
    background-color: $backgroundColor;

    &::before { // seed node
      content: '';
      display: block;
      box-sizing: border-box;
      background-color: white;
      border: solid 1px $branchColor;
      width: 7px;
      height: 7px;
      position: absolute;
      top: 13px;
      left: 23px;
    }
  }

  .treeeWrap.capture::before {
    display: none;
  }

  .seedNode {
    @extend %typo-caption;

    display: inline-block;
    background-color: transparent;
    padding: 2px 4px;
    border-bottom: solid 1px $stroke;
    color: $secondary-d;
  }

  .treee {
    padding: 0 0 0 2 * $branchHorizontalBase;
    margin: 0 0 80px;
    list-style-type: none;
    position: relative;

    &::before {
      // vertical branch
      content: '';
      display: block;
      width: 0;
      border-left: dotted $branchWidth $branchColor;
      height: calc(#{$branchOffset});
      position: absolute;
      top: 0;
      left: $branchHorizontalBase;
    }

    &::after {
      // vertical branch
      content: '';
      display: block;
      width: 0;
      border-left: dotted $branchWidth $branchColor;
      height: calc(100% - #{$branchOffset*2});
      position: absolute;
      top: $branchOffset;
      left: $branchHorizontalBase;
    }
  }

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .list-complete-item {
    transition: all 0.2s;
  }

  .list-complete-enter,
  .list-complete-leave-to {
    opacity: 0;
    transform: translateX(30px);
  }

  .list-complete-leave-active {
    position: absolute;
  }

  .test {
    background-color: #000;
    color: white;
    position: fixed;
    left: 0;
    top: 0;
    width: 600px;
    min-height: 300px;
    z-index: 9999999;
    white-space: pre;
    opacity: 0.8;
    border: solid 5px skyblue;
  }
</style>
