<template>
  <transition name="shortkeyHelp">
    <div class="shortkeyHelp">
      <div
        class="content"
      >
        <h2 class="title">Export to text</h2>
        <p class="fileName">File name: {{title}}.txt</p>
        <textarea
          class="preview"
          v-shortkey.avoid
          v-bind:value="structuredTreee({index: showIndex, description: showDescription})"
        />
        <div class="actionMenu">
          <div class="configrations">
            <label class="configration">
              <input
                type="checkbox"
                v-model="showIndex"
              > Branch number
            </label>
            <label class="configration">
              <input
                type="checkbox"
                v-model="showDescription"
              > Description
            </label>
          </div>
          <div class="actions">
            <flat-button
              class="actionButton"
              @click="$emit('clickCloseExportDialog')"
            >close</flat-button>
            <raised-button-primary
              @click="saveFile"
            >export</raised-button-primary>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import { createNamespacedHelpers } from 'vuex'
import RaisedButtonPrimary from '../../../components/common/atoms/RaisedButtonPrimary'
import FlatButton from '../../../components/common/atoms/FlatButton'

const treeeEditorStore = createNamespacedHelpers('treeeEditorStore')

export default {
  components: {
    RaisedButtonPrimary,
    FlatButton
  },
  data () {
    return {
      showIndex: true,
      showDescription: true
    }
  },
  computed: {
    ...treeeEditorStore.mapState([
      'title'
    ]),
    ...treeeEditorStore.mapGetters([
      'structuredTreee'
    ])
  },
  methods: {
    closeExportDialog () {
      this.$emit('clickCloseExportDialog')
    },
    saveFile () {
      const data = this.structuredTreee({
        index: this.showIndex,
        description: this.showDescription
      })
      const blob = new Blob([data], { type: 'text/plain' })
      const e = document.createEvent('MouseEvents')
      const a = document.createElement('a')
      a.download = `${this.title}.txt`
      a.href = window.URL.createObjectURL(blob)
      a.dataset.downloadurl = ['text/plain', a.download, a.href].join(':')
      e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
      a.dispatchEvent(e)
      this.$emit('clickCloseExportDialog')
    }
  },
  directives: {
    ClickOutside
  }
}
</script>

<style scoped lang="scss">
@import "../../../styles/constants";

.shortkeyHelp {
  position: fixed;
  background-color: $smoke-1;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shortkeyHelp-enter-active,
.shortkeyHelp-leave-active {
  transition: opacity 0.1s;
}

.shortkeyHelp-enter,
.shortkeyHelp-leave-to {
  opacity: 0;
}

.title {
  @extend %typo-h2;

  margin: 0 0 2px;
}

.fileName {
  @extend %typo-caption;

  color: $secondary-d;
  margin: 0;
}

.content {
  background-color: $white;
  padding: 20px;
  box-sizing: border-box;
  min-width: 600px;
  max-width: 95vw;
  max-height: 95vh;
  overflow: scroll;
  border-radius: 6px;
}

.preview {
  @extend %typo-caption;

  color: $primary-d;
  font-family: inherit;
  width: 100%;
  max-width: 100%;
  max-height: calc(95vh - 152px);
  min-width: calc(600px - 40px);
  min-height: 300px;
  height: 300px;
  margin: 0 0 16px;
  padding: 8px;
  box-sizing: border-box;
  white-space: pre;
  border: solid 1px $stroke;
}

.actionMenu {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.configrations {
  display: flex;
  align-items: center;
}

.configration {
  @extend %typo-body1;

  cursor: pointer;
  color: $primary-d;
  margin: 0 20px 0 0;
}

.configration:last-child {
  margin: 0;
}

.actions {
  display: flex;
  align-items: center;
}

.actionButton {
  margin: 0 16px 0 0;
}
</style>
