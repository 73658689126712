<template>
  <div class="loadingIndicator">
    <gradiate-triangles class="triangles"/>
    <b class="status">LOADING</b>
  </div>
</template>

<script>
import Vue from 'vue'
import GradiateTriangles from '../../../images/GradiateTriangles.svg'

export default Vue.extend({
  components: {
    GradiateTriangles
  }
})
</script>

<style scoped lang="scss">
  @import '../../../styles/constants';

  .loadingIndicator {
    display: flex;
    height: 100px;
    width: 100px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .triangles {
    width: 80px;
    margin: 0 0 10px;
  }

  @keyframes t {
    0% {
      transform: translateY(-200px);
    }

    100% {
      transform: translateY(0);
    }
  }

  .t {
    animation-name: t;
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-fill-mode: both;
  }

  .t1 {
    animation-delay: 0s;
  }

  .t2 {
    animation-delay: 0.1s;
  }

  .t3 {
    animation-delay: 0.2s;
  }

  .t4 {
    animation-delay: 0.3s;
  }

  .t5 {
    animation-delay: 0.4s;
  }

  .status {
    @extend %typo-caption;

    color: $primary-d;
  }
</style>
